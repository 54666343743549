export default {
  title: {
    header: 'Übersicht',
  },
  form: {
    button: 'Aktualisieren',
  },

  team_info: {
    header: 'Nutzer:innen Verwaltung',
    filter_search: {
      search: 'Nach Namen suchen',
      status: 'Status filtern',
      role: 'Rolle filtern',
    },
    invite_members: '+ Nutzer:innen einladen',
    invite_popup_header: 'Teammitglieder hinzufügen',
    table_header: {
      no_members: 'Es gibt noch keine Teammitglieder',
      full_name: 'Name',
      status: 'Status',
      email: 'E-Mail',
      role: 'Rolle',
      license_id: 'Lizenz ID',
      tooltip_status: `
        <div>Ausstehend - Warte auf Annahme der Einladung.</div>
        <div>Aktiv - Account aktiv, automatische Verlängerung.</div>
        <div>Pausiert - Account aktiv, wird nicht verlängert.</div>
        <div>Inaktiv - Account hat keinen Plattformzugang mehr</div>
        `,
    },

    member: {
      role_admin: 'Administrator:in',
      role_member: 'Lerner:in',
      member_readonly: 'Lerner:in light',
      status_active: 'Nutzerinfo',
      status_suspend: 'Pausieren',
      status_delete: 'Deaktivieren',

      status_re_activate: 'Reaktivieren',
      status_de_activate: 'Deaktivieren',
      status_remove: 'Aus Team entfernen',
      send_invite: 'Zu wîse up einladen',
      resend_invite: 'E-Mail erneut zusenden',
      cancel_invite: 'Einladung abbrechen',

      transfer_owner: 'Eigentümerschaft übertragen',
    },
  },

  remove_popup: {
    header: '{invitedUser} aus dem {organisation} Team entfernen?',
    text: 'Dieser Vorgang kann nicht rückgängig gemacht werden. Der Account wird automatisch in einen privaten Account umgewandelt.',
    btn_cancel: 'Abbrechen',
    btn_remove: 'Entfernen',
  },

  change_member_role_modal: {
    text_before: 'Rolle',
    text_after: 'allen ausgewählten Nutzer:innen zuweisen?',
    btn_cancel: 'Abbrechen',
    btn_yes: 'Ja',
  },

  bulk_remove_popup: {
    text: 'Wollen Sie wirklich alle ausgewählten Nutzer:innen dauerhaft aus Ihrer Organisation entfernen?',
  },

  transfer_owner_popup: {
    header: 'Eigentümerschaft von {organization} an {userName} übertragen? ',
    btn_cancel: 'Abbrechen',
    btn_remove: 'Übertragen',
  },

  page_list: {
    nav_labale: 'Seitennavigation',
    next: 'Nächste',
    previous: 'Vorherige',
  },

  delete: {
    title: 'Organisation löschen',
    info: {
      text: 'Dieser Vorgang kann nicht rückgängig gemacht werden',
      action: 'Organisation löschen',
    },

    caption: {
      text_1: 'Dies ermöglicht Ihnen, Ihre Organisation "{name}" zu löschen.',
      text_2: 'Ihre Teammitglieder werden in private Konten umgewandelt.',
      text_3:
        'Die bestehenden Lizenzen können noch für den verbleibenden Abrechnungszeitraum (bis {date}) genutzt werden.',
    },

    confirm: {
      title: 'Organisation löschen?',
      text: 'Dieser Vorgang kann nicht rückgängig gemacht werden. Accounts werden automatisch in private Lizenzen umgewandelt.',
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
  },

  popup_info: {
    signed_up: 'Registriert seit',
    user_ID: 'Nutzer ID:',
    status: 'Status',
    manager: {
      title: 'Persönlicher Vorgesetzter',
      info: 'Führungskraft auswählen',
      placeholder: 'Führungskraft auswählen',
    },
    license: {
      title: 'Lizenz',
      ID: 'Lizenz ID',
      price: 'Lizenzkosten',
      price_amount: '€149.00',
      status: 'Lizenz Status',
      date_start: 'Startdatum Lizenz',
      date_end: 'gültig bis',
      next_billing_date: 'Nächster Zahlungstermin',
    },
    role: 'Rolle',
    auto_recharge: {
      lable: 'Automatische Verlängerung',
      on: 'Ein',
    },
  },

  licenses: {
    total: 'Gesamt',
    active: 'Aktiv',
    pending: 'Ausstehend',
    available: 'Verfügbar',
  },

  enterprise: {
    header: 'Lizenzen',
    request_licenses: 'Lizenzen hinzufügen',
    request_licenses_button: 'Kostenpflichtig bestellen',
    licenses_available: 'Verfügbare Lizenzen: {amount}',
    input: 'Wie viele zusätzliche Lizenzen wollen Sie hinzufügen?',
    valid_until: `Ihre bestehenden Lizenzen haben eine Gültigkeitsdauer bis {date}. Die Dauer der neuen Lizenzen und deren Verrechnung werden für das erste Jahr aliquot daran angepasst.`,
    cost_preview: 'Kostenvorschau',
    cost_description:
      'Ihre neuen Lizenzen sind zu folgendem Preis sofort verfügbar:',
    license_count: '{licenses} Lizenzen',
    subtotal: 'Zwischensumme',
    adjusted_access: 'Kosten aliquot laufendes Jahr (bis {date})',
    individual_price: 'Noch Fragen?',
    contact_request_hint: 'Wir sind gerne für Sie da.',
    contact_sales: 'Kontaktaufnahme anfragen',

    request_contact: {
      header: 'Vielen Dank für Ihre Anfrage',
      paragraph: 'Das wîse up Team wird sich in Kürze bei Ihnen melden',
      btn_confirm: 'zurück zur Hauptseite',
    },
  },
}
